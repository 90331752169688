import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';
import { FilterObject } from 'state-domains/domain/drillhole';
import { buildConfigurationTableViewsUrl } from 'state-domains/network';
import { convertToCamel } from 'state-domains/utils/typeCast';

import { sendRetrieveRequest } from '../../../utils';

export const loadConfigurationTableViews = (filters: FilterObject[]): Observable<any> => {
    const obs = sendRetrieveRequest(buildConfigurationTableViewsUrl(filters));
    return obs.pipe(
        switchMap(({ response }: AjaxResponse<any>) => {
            const items = convertToCamel<any[]>(response.TableView);
            return observableOf({
                items,
            });
        }),
    );
};
